import React from "react"
import { GlobalSection, GlobalContainer } from "../components/GlobalStyle"
import SEO from "../components/seo"
import styled from "styled-components"
import PageHeader from "../components/PageHeader"
import headerImage from "../images/slider/slide-2.jpg"

const StyledPrivacyPolicyWrap = styled.div`
  h5 {
    font-size: 2em;
    margin-top: 40px;
  } 
  li {
    list-style: none;
  }
    ul {
      padding-left: 20px;
    }
`

const PrivacyPolicy = () => (
  <>
    <SEO title="Polityka prywatności" />
    <PageHeader image={headerImage}>
      <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">Polityka prywatności</h1>
      <p data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">Opublikowana dnia 12.06.2019r</p>
    </PageHeader>
    <GlobalSection bg="#F4F4F4">
      <GlobalContainer>
        <StyledPrivacyPolicyWrap>
          <p>
            Niniejsza Polityka Prywatności, została sporządzona w celu wykazania, że dane osobowe są
            przetwarzane i zabezpieczone zgodnie z wymogami prawa, dotyczącymi zasad przetwarzania i
            zabezpieczenia danych, w tym z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/
            z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 55/46/WE
            (dalej RODO).
          </p>
          <ul>
            <li>
              <h5>1. Informacje o przetwarzaniu danych osobowych</h5>
              <p>
                Zgodnie z art. 13 ust. 1 i ust. 2 RODO, informujemy, iż:
              </p>
              <ul>

                <li>1.1. Administratorem Pańskich danych osobowych jest
                  firma Gormanet, 81-876 Sopot, ul. Leśna 1
                  , adres e-mail: biuro@lesnahub.pl

                </li>
                <li>1.2. Inspektor Pańskich danych osobowych jest dostępny pod adresem e-mail: biuro@lesnahub.pl

                </li>
                <li>1.3. Podstawą prawną przetwarzania Pańskich danych jest:

                </li>
                <ul>
                  <li>1.3.1. wyrażona zgoda na przetwarzanie danych osobowych w celach marketingowych (na
                    podstawie art. 6 ust.1 lit. a RODO),

                  </li>
                  <li>1.3.2. wypełnienie obowiązku prawnego ciążącego na administratorze (na podstawie art. 6 ust.
                    lit. c RODO).
                  </li>
                </ul>

                <li>1.4. Dane osobowe mogą być przekazywane: podmiotom przetwarzającym dane osobowe na zlecenie
                  Administratora, innym administratorom danych osobowych, jeśli mają prawnie uzasadniony
                  interes. Za inne podmioty przetwarzające dane na zlecenie Administratora uważa się w
                  szczególności: dostawców usług informatycznych.
                </li>

                <li>1.5. Posiada Pani/ Pan prawo do:
                </li>

                <ul>

                  <li>1.5.1. dostępu do treści swoich danych,
                  </li>

                  <li>1.5.2. ich sprostowania,
                  </li>

                  <li>1.5.3. usunięcia,
                  </li>

                  <li>1.5.4. ograniczenia przetwarzania,
                  </li>

                  <li>1.5.5. prawo do przenoszenia danych,
                  </li>

                  <li>1.5.6. prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem
                    przetwarzania.
                  </li>
                </ul>

                <li>1.6. W celu realizacji swoich uprawnień należy złożyć stosowne żądanie Administratorowi na podany
                  w punkcie 1 adres.
                </li>

                <li>1.7. Ma Pani/Pan prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych w
                  przypadku uznania, że przetwarzanie danych osobowych narusza przepisy ogólnego
                  rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 roku lub Ustawy.
                </li>

                <li>1.8. Pani/Pana dane osobowe mogą być przetwarzane w sposób zautomatyzowany w tym polegający
                  na profilowaniu danych.
                </li>

              </ul>
            </li>
          </ul>


          <li><h5>2. Formularz kontaktowy</h5></li>

          <ul>
            <li>2.1. Strona internetowa lesnahub.pl umożliwia skontaktowanie się za pomocą formularza
              kontaktowego znajdującego się na stronie „Kontakt”.
            </li>

            <li>2.2. W celu skontaktowania się z, Gormanet konieczne jest podanie
              danych osobowych, które zostaną wykorzystane przez Administratora wyłącznie w celu
              skontaktowania się klientem lub potencjalnym klientem.
            </li>

            <li>2.3. Przed podaniem danych należy zapoznać się z Polityką Prywatności oraz wyrazić zgodę na
              przetwarzanie podanych danych osobowych.
            </li>

          </ul>
          <li><h5>3. Bezpieczeństwo informacji</h5></li>
          <ul>
            <li>
              3.1. Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych
              danych osobowych określone w RODO zapewniające zwiększoną ochronę i bezpieczeństwo
              przetwarzania danych osobowych, odpowiednie do zagrożeń oraz kategorii danych objętych
              ochroną, w szczególności zabezpiecza technicznie i organizacyjnie dane przed ich
              udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną,
              przetwarzaniem z naruszeniem prawa oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.
              Zbiór zgromadzonych danych osobowych przechowywany jest na zabezpieczonym serwerze
              oraz dane są chronią również poprzez wewnętrzne procedury Administratora z zakresu
              przetwarzania danych osobowych i polityki bezpieczeństwa informacji.
            </li>
          </ul>

          <li><h5>4. Pliki cookies</h5></li>

          <ul>
            <li>4.1. Pliki cookies – są to dane informatyczne, w szczególności pliki tekstowe, zapisywane przez serwery Administratora na urządzeniu końcowym użytkownika (min. Komputer, smartfon itp.).
            </li>
            <ul>
              <li>4.1.1. Serwery mogą odczytać pliki cookies przy każdorazowym połączeniu się z urządzenia końcowego.
              </li>
              <li>4.1.2. Przeglądarki internetowe w ustawieniach domyślnych akceptują tworzenie plików
                cookies na urządzeniu użytkownika.
              </li>
              <li>4.1.3. Odwiedzający stronę może w każdej chwili usunąć pliki cookies umieszczone na
                jego urządzeniu końcowym za pomocą opcji dostępnych w jego przeglądarce
                internetowej lub zablokować możliwość ich tworzenia.
              </li>
              <li>4.1.4. Zablokowanie możliwości tworzenia plików cookies może negatywnie wpłynąć na
                działanie strony.
              </li>
            </ul>
            <li>4.2. Cele tworzenia plików cookies:
            </li>
            <ul>
              <li>4.2.1. tworzenia statystyk oraz ich analizowania,
              </li>
              <li>4.2.2. korzystanie z formularzy kontaktowych,
              </li>
              <li>4.2.3. pliki nie zawierają żadnych danych osobowych. Imię, nazwisko, e-mail lub adres IP
                nie są za ich pośrednictwem zapisywane. Nie służą one tworzeniu indywidualnego
                profilu aktywności odwiedzającego stronę.
              </li>
            </ul>
            <li>4.3. Rodzaje tworzonych oraz używanych plików cookies:
            </li>
            <ul>
              <li>4.3.1. pliki cookies statystyczne – służące do zliczania statystyk dotyczących serwisu.
              </li>
              <li>4.3.2. wymagane pliki cookies – są to pliki niezbędne dla sprawnego poruszania się po
                Serwisie i korzystania z wszystkich funkcji Serwisu.
              </li>
            </ul>
          </ul>
        </StyledPrivacyPolicyWrap>
      </GlobalContainer>
    </GlobalSection>
  </>
)

export default PrivacyPolicy
